.img_container{
    position: absolute;
    top: -40px;
    z-index: 100;
    width: 100vw;
    height: 110vh;
    background: white;
    cursor:wait;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -ms-user-select: none;
}

.img_center{
    display: flex;
    justify-content: center;
    margin-top: 15vh;
}

.cycle_loading {
    position: absolute;
    margin-top:-5px;
    border: 20px solid #D6EEF0;
    border-radius: 50%;
    border-top: 20px solid #00A79D;
    width: 170px;
    height: 170px;
    animation: spinner 2s linear infinite;
}
  
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading {
    background-image: url('./images/NDC_logo_black.png');
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
    max-width: 200px;
}
