:root {
    --bodybackground: #f0ffff;
    --boxbackground: white;
    --border: #e3e3e3;
    --shadow: #0000001a;
    --unselected_tab: #f0f0f0;
    --unselected_tab_border: #9e9e9e;
    --unselected_tab_color: #828282;
    --selected_tab:#0d7777;
    --regular_teal: #00A79D;
    --light_teal: #1ac1b7;
    --dark_teal: #00746a;
    --tab_focus: hsl(208, 99%, 50%);
}
/*[data-theme='dark'] {
    --bodybackground: #151515;
    --boxbackground: #3f3f3f;
    --border: #ffffff00;
    --shadow: rgba(255, 255, 255, 0);
    --unselected_tab: #151515;
    --unselected_tab_border: #9b9b9b;
    --unselected_tab_color: #b5b5b5;
    --selected_tab:#3f3f3f;
    --selected_tab_color: white;
}*/

/*Top navbar*/
.nav-container{
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 50px 0 50px;
    max-width: 1330px;
    margin-left: auto;
    margin-right: auto;
}

#first-row-nav{
    background: black;
    display: block;
}

.nav-container-home{
    max-width: 1290px;
    padding: 15px 5px;
    margin-left: auto;
    margin-right: auto;
}

.second-row-nav {
    background-color: white;
    border-bottom: var(--border) solid 1px;
    box-shadow: 0px 1px 3px 0px #00000080;    
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 9999;
    transition: 0.3s ease-in-out;
}

.second-row-nav[disabled]{
    background: #ffffffe6;
}

.nav-container-home a {
    color: white;
    text-decoration: none;
    font-family: 'Roboto';
}

.nav-container-home a:hover {
    font-weight: 900;
}

/*Body*/
.body {
    background: var(--bodybackground);
    z-index:-100;
    width: 100vh;
    overflow-x: hidden;
    position:relative;
}
/*Two columns switch to each other depending on clickable button */
.column-close {
    background-color: #282729;
    min-height:150vh;
    top: -0px;
    z-index: 2;
    height:max-content;
    width: 240px;
    margin-left: -240px;
    float: left;
    transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    position:fixed;
}
.column-open {
    background-color: #282729;
    min-height:100vh;
    top: -0px;
    z-index: 2;
    height:max-content;
    width: 240px;
    margin-left: 0;
    float: left;
    transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    position:fixed;
    color: white;
}

/*Stylizing column button */
.for-column-icon {
    color: var(--black);
    padding:5px;
    padding-top: 10px;
    user-select: none;
}
.for-column {
position: static;
    z-index: 4;
	cursor: pointer;
	transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    border: None;
    background: None;
}
.for-column-close{
    position: absolute;
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    user-select: none;
    font-size: 16px;
    padding: 10px;
    margin-top: 5px;
    margin-left:-25px;
}

/*Button for left black column*/
/*li {
    display: inline-block;
}*/
.column-text {
    text-align: left;
    font-size: 16px;
    padding: 15px 5px 15px 25px;
    margin:0;
    transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    cursor: pointer;
}
.column-text:hover {
    background:#535254;
}

.nav-dropdown{
    background: #F6F6F7;
    border-radius: 10px;
    border: 0.5px #008e85 solid;
    z-index:9999;
}

.nav-text {
    text-align: left;
    font-size: 16px;
    padding: 15px 5px 15px 25px;
    margin:0;
    cursor: pointer;
}

.nav-text:hover{
    background: #ECEDF0;
}
.nav-text:hover > .nav-item {
    rotate: 10deg;
    background: #ECEDF0;
    transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
}

.nav-text > .nav-item {
    rotate: 0deg;
    transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
}


/*Accessibility Color*/
#access-close-icon {
    position: relative;
    background: transparent;
    height: 42px;
    width: 42px;
    border: none;
    cursor: pointer;
    float: right;
    margin: 0px;
    margin-top: -20px;
 }

 #access-close-icon:hover:before, #access-close-icon:hover:after{
    background: var(--black);
    transition: 0.3s ease-in-out;
 }
 
 #access-close-icon:before, #access-close-icon:after {
   content: "";
   position: absolute;
   background: var(--unselected_tab_color);
   transition: 0.3s ease-in-out;
 }
 
 #access-close-icon:before {
   width: 2px;
   height: 16px;
   transform: rotate(-45deg);
 }
 
 #access-close-icon:after {
    width: 2px;
    height: 16px;
    transform: rotate(45deg);
 }

.main-grid {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    padding-right:20px;
    width: 100%;
    margin: 18px 0 18px 0;
}
.main-a {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-template-rows: 1;
    display: flex;
    justify-content: flex-start;
    width: auto;
}
.main-b {
    grid-column-start: 10;
    grid-column-end: 10;
    display: flex;
    justify-content: flex-end;
    width: auto;
}
.Jonah-nav-text{
    font-family: 'Roboto';
    font-size: 16px;
    color: var(--unselected_tab_color);
    margin: auto;
    margin-left: 10px;
}
#Jonah-report-generator-text{
    font-family: 'Roboto';
    font-size: 16px;
    color: var(--bodybackground);
    margin: auto;
    margin-left: 10px;
}
.flex-invisible-button{
    display: flex;
    justify-content: flex-end;
}

.invisible-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 4;
	cursor: pointer;
	transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    padding: 18px;
    border: None;
    background: None;
    cursor: pointer;
}
.invisible-button:hover {
    background: var(--border);
}
.iconButton{
    font-size: 30px;
    text-align: right;
    color: var(--unselected_tab_color);
    cursor: pointer; 
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -ms-user-select: none;
    transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
}
.iconWhiteButton{
    font-size: 30px;
    text-align: right;
    color: var(--bodybackground);
    cursor: pointer; 
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -ms-user-select: none;
    transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
}
.popup-close {
    display: none;
    position:fixed;
}
.popup-open {
    display: unset;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
}
.accessible-container{
    display:contents;
}
#accessible-id{
    margin-top: 60px;
}
.accessible-box {
    background: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: auto;
    border-radius: 10px 10px 10px 10px;
    padding:10px;
    border: rgb(227, 227, 227) solid 1px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    max-width: 500px;
    max-height: 250px;
}
.accessible-color {
    display:flex; 
    justify-content:center;
    padding: 40px;
 }
 #title{
    font-size: 20px;
    color: var(--unselected_tab_color);
    padding: 0px 0px 5px 0px;
    font-weight: 900;
    font-family: 'Roboto';
}
.accessible-close{
    background: none;
    border: none;
    color: var(--unselected_tab_color);
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 16px;
    padding:5px 10px;
    margin-right: auto;
    margin-left: 90%;
    position:relative;
    user-select: none;
    top: -20px;
}

.first-footer{
    border-top: 5px solid #444444;
    background: var(--black);
    margin-top: 70px;
    text-align: center;
}

.first-footer img{
    margin-top: -70px;
}

.first-footer .img-wrap{
    margin-left: auto;
    margin-right: auto;
}

.footer-container {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    color: white;
}

.footer-grid{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.footer-grid-column{
    text-align: left;
}

.link-to-main-site{
    display: inline-block;
    color: white;
    text-decoration: none;
    background: var(--regular_teal);
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 900;
    font-family: 'Roboto';
    margin-top: 20px;
    transition: 0.3s ease-in-out;
}

.link-to-main-site:hover{
    background: var(--dark_teal);
}

.first-footer .footer-container{
    padding: 0px 20px 150px 20px;
}

.footer-title{
    font-size: 30px;
    font-family: 'Roboto Slab';
    font-weight: 900;
}

.footer-context{
    font-size: 16px;
    font-family: 'Roboto';
}

.second-footer{
    background: black;
    margin-top: -70px;
}

.second-footer .footer-container{
    padding: 0px 20px 50px 20px;
}

.footer-licensed{
    font-size: 13px;
    text-align: center;
}

.footer-flex-img-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0 20px 0;
}

.NDC-image{
    height: 85px;
    padding: 5px;
}

@media only screen and (max-width: 1000px), (max-height: 790px){
    .second-row-nav {
        position: unset;
    }
    
    .second-row-nav[disabled]{
        background: white;
    }
}

@media only screen and (max-width: 700px){
    .nav-container-home{
        text-align: center;
    }
    .footer-container {
        text-align: center;
    }
    .footer-flex-img-wrap{
        flex-direction: column;
    }
    .NDC-image{
        max-height: 50px;
    }
    .Jonah-nav-text{
        display: none;
    }
    .nav-container{
        margin: 0;
    }
    .footer-grid{
        display:unset;
    }
    .footer-grid-column{
        text-align: center;
    }
}

/*Open div to Get API*/
.open-get-API{
    position: fixed;
    height: 110vh;
    width: 100vw;
    background: #0000007f;
    visibility:visible;
    opacity:1;
    z-index: 11;
    margin-top: -70px;
}

.open-get-API[disabled]{
    visibility: hidden;
    opacity: 0;
}

.menuGetAPI{
    width: 80%;
    background: var(--boxbackground);
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    border-radius: 10px;
}

#api-menu-padding{
    padding: 50px;
    padding-top: 10px;
}

.get-api-fieldset{
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 900;
    color: var(--unselected_tab_color);
    border: none;
    margin-top: 20px;
}

.get-api-fieldset input{
    font-size: 16px;
    font-family: 'Roboto';
}

.get-api-fieldset #text{
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    border-bottom: 2px var(--black) solid;
    text-align: left;
}

.get-api-fieldset #submit{
    width: 100px;
    height: 41px;
    border: none;
    padding: 10px;
    background: var(--selected_tab);
    color: var(--boxbackground);
    font-weight: 900;
    border-bottom: 2px var(--black) solid;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.get-text-and-submit-together{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
}

.get-api-fieldset:hover #submit{
    opacity: 0.8;
    transition: 0.2s ease-in-out;
}

#api-menu-close {
    position: relative;
    background: transparent;
    height: 42px;
    width: 42px;
    border: none;
    cursor: pointer;
    float: right;
    margin: 10px;
 }

 #api-menu-close:hover:before, #api-menu-close:hover:after{
    background: var(--black);
    transition: 0.3s ease-in-out;
 }
 
 #api-menu-close:before, #api-menu-close:after {
   content: "";
   position: absolute;
   background: var(--unselected_tab_color);
   transition: 0.3s ease-in-out;
 }
 
 #api-menu-close:before {
   width: 2px;
   height: 16px;
   transform: rotate(-45deg);
 }
 
 #api-menu-close:after {
    width: 2px;
    height: 16px;
    transform: rotate(45deg);
 }

@media only screen and (max-width: 650px){
    .menuGetAPI{
        width: 90%;
    }
}


